import HelggLogo from "../assets/img/helgg-full-logo.png"
const ScaleLoader = () => {
  return (
	<div className='h-[100svh] flex justify-center items-center w-full'>
		<div className='animate-pulse w-2/4'>
			<img src={HelggLogo} alt="" />
		</div>
	</div>
  )
}

export default ScaleLoader