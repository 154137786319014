import DashboardContainer from "../layouts/DashboardContainer";
import FleetModule from "../modules/Fleet";
import { withAuth } from "../utils/hoc's/withAuth";

const Fleet = () => {
  return (
    <DashboardContainer>
      <FleetModule />
    </DashboardContainer>
  );
};

export default withAuth(Fleet);