import React from "react";
import DetailPane from "./DetailPane";

const InfoTab = ({fleetdata}) => {

    return (
        <div className={`flex flex-col gap-4 w-full `}>
            <DetailPane
                title="Vehicle ID"
                description="Each vehicle has a special ID"
                value={fleetdata.id}
            />
            <DetailPane
                title="Model"
                description="Special ID for your vehicle"
                value={fleetdata.model}
            />
            <DetailPane
                title="Date added"
                description="The date the car was registered"
                value={fleetdata.date}
            />
            <DetailPane
                title="Color"
                description="The color of the vehicle"
                value={fleetdata.color}
            />
            <DetailPane
                title="Status"
                description="This shows vehicle status"
                value={fleetdata.status}
            />
            <DetailPane
                title="Price"
                description="Amount of scooter rentals"
                value={fleetdata.price}
            />
            <DetailPane title="Deactivate Vehicle" description="Deactivate Vehicle Connections" />
        </div>
    );
};

export default InfoTab;
