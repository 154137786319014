import React from "react";
import { ToastContainer } from "react-toastify";
import { AuthProvider } from "./context/AuthContext";
import AppRoutes from "./Routes";
import "react-toastify/dist/ReactToastify.css";

function App() {
    return (
        <AuthProvider>
            <ToastContainer autoClose={3000} newestOnTop={false} closeOnClick />
            <div className="flex min-h-[100svh] md:hidden text-center text-2xl px-8 justify-center items-center">Please There is no mobile version available for now</div>
            <div className="hidden md:block">
                <AppRoutes />
            </div>
        </AuthProvider>
    );
}

export default App;
