import React from "react";
import FormInput from "../../FormInput";

const PasswordCard = () => {
    return (
        <div className="">
            <div className="flex w-full justify-center flex-col gap-4 border-l-primary border-r-primary border-r-2 border-l-2 rounded-md max-w-4xl bg-[#F5F5F5] px-20 py-28">
                <div className="flex flex-col justify-center text-center">
                    <h1 className="font-bold  text-3xl">Forgot Password</h1>
                    <p className="max-w-md">
                        Enter The Email Address You Used To Register On Helgg To Receive a Reset
                        Password Link
                    </p>
                </div>

                <FormInput
                    label="Email Address"
                    name="email-address"
                    id="email-address"
                    type="text"
                    placeholder=""
                />
                <button className="text-white bg-primary py-2 px-4 rounded-md font-semibold">Continue</button>
            </div>
        </div>
    );
};

export default PasswordCard;
