import ToggleFleet from '../../ToggleFleet/ToggleFleet';


const ControlTab = ({communicationControls, vehicleControls}) => {
  return (
	<div className="flex flex-col gap-y-4 ">
		<div className='flex justify-between gap-x-8 border-b pb-4 border-b-[#CFCFCF]'>
			<div className='flex flex-col w-full'>
				<h1 className='text-[17px]'>Vehicle Controls</h1>
				<p>Set all necessary controls that you want this vehicle to have</p>
			</div>
			<div className='flex flex-col gap-y-4 w-full'>
				<ControlSelect  title="Disable Vehicle" description="This disable active vehicle and makes it unusable" isChecked={vehicleControls.disabled}/>
				<ControlSelect  title="Vehicle Alarm" description="This triggers vehicle alarm incase of theft or wrong activation " isChecked={vehicleControls.alarm}/>
				<ControlSelect  title="Disable Simcard" description="Once disabled, vehicle will be disconnected from FMS" isChecked={vehicleControls.disabledSimcard}/>
				<ControlSelect  title="Enable Free Access" description="This control allows the use of vehicle without unlocking" isChecked={vehicleControls.freeAccess}/>
			</div>
		</div>
		<div>
		<div className='flex justify-between gap-x-8 pt-4 '>
			<div className='flex flex-col w-full'>
				<h1 className='text-[17px]'>Communication Controls</h1>
				<p>Set alarm to trigger vehicle incase of theft or wrong activation </p>
			</div>
			<div className='flex flex-col gap-y-4 w-full'>
				<ControlSelect  title="Ping Vehicle" description="Test vehicle communications with pings" isChecked={communicationControls.disabled}/>
				
			</div>
		</div>
		</div>
	</div>
  )
}

export default ControlTab

const ControlSelect = ({title, description, isChecked}) => {
	return (
		<div className='flex w-full '>
			<div className='w-full'>
				<h1 className='text-[17px]'>{title}</h1>
				<p className=''>{description}</p>
			</div>
			<div className='w-fit'>
				<ToggleFleet label={title} isChecked={isChecked} />
			</div>

		</div>
	)
}