/* eslint-disable no-unused-vars */
import React, { useContext, createContext, useEffect } from "react";
import { useState } from "react";
import { auth } from "../firebase.js";
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    sendEmailVerification,
} from "firebase/auth";
import { IoEllipseSharp } from "react-icons/io5";

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}
export function AuthProvider({ children }) {
    const [user, setUser] = useState(() => {
        const storedUser = localStorage.getItem("user");
        return storedUser ? JSON.parse(storedUser) : null;
    });

    function signup(email, password) {
        return createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                return sendEmailVerification(auth.currentUser)
                    .then(() => {
                        const message = "Verfication email sent.";
                        return message;
                    })
                    .catch((error) => {
                        throw error;
                    });
            })
            .catch((error) => {
                throw error;
            });
    }
    function login(email, password) {
        return signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                if (userCredential.user.emailVerified) {
                    setUser(userCredential.user);
                    localStorage.setItem("user", JSON.stringify(userCredential.user));
                    return userCredential;
                } else {
                    throw new Error("Please verify your email before logging in");
                }
                // const user = userCrendential.user
            })
            .catch((error) => {
                throw error;
            });
    }

    function logout() {
        return signOut(auth)
            .then(() => {
                setUser(null);
                localStorage.removeItem("user");
            })
            .catch((error) => {
                throw error;
            });
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setUser(user);
            if (user) {
                localStorage.setItem("user", JSON.stringify(user));
            } else {
                localStorage.removeItem("user");
            }
        });
        return unsubscribe;
    }, []);

    const value = {
        user,
        signup,
        login,
        logout,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
