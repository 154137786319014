import React, { useState } from 'react'
import { MdKeyboardArrowDown } from 'react-icons/md';

import { addDays, format } from "date-fns";
import { DateRange } from "react-date-range";

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';

const Images = () => {

  const [dates, setDates] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection'
    }
  ]);

  const [openDate, setOpenDate] = useState(false);

  return (
    <section className="flex flex-col w-full px-[2rem] py-4">
      <div className="flex flex-row justify-between my-2">
        <h4 className="text-[30px] text-[#000] font-semibold px-4">Image Files</h4>
      </div>

      <div className="mt-5">
        <div className="bg-white w-full p-3 border-[1px] border-[#e0e0e0] border-b-0 rounded-md">
          <div className="flex items-center justify-between">
            <div className="flex flex-row items-center justify-center gap-5">
              <h4 className='font-semibold text-[25px]'>28 Items Saved</h4>

              <hr className='h-[15px] w-[1px] border-l-[1px] border-[#e0e0e0]' />

              <div className="flex flex-row items-center justify-center gap-2 bg-[#f3f2f2] border-[1px] border-[#e0e0e0] rounded-md py-[2px] px-2 ">
                <span className="text-[14px]">Filter</span>

                <MdKeyboardArrowDown />
              </div>
            </div>

            <div className="flex flex-row items-center gap-5">
              <p className='text-black text-sm cursor-pointer bg-[#e6e6e6] py-2 px-3 rounded-md'>Today</p>
              <p className='text-grey text-sm cursor-pointer'>This Week</p>
              <p className='text-grey text-sm cursor-pointer'>This Month</p>
              <hr className='h-[20px] w-[1px] border-l-[1px] border-[#e0e0e0]' />
              <div className='text-grey text-sm cursor-pointer border-solid border-[2px] border-[#f6f6f6] p-1 rounded-md relative'>
                <span className='text-[#1A73EB]' onClick={() => setOpenDate(!openDate)}>
                  {`${format(dates[0].startDate, "dd/MM/yyyy")} to ${format(dates[0].endDate, "dd/MM/yyyy")}`}
                </span>
                {openDate && 
                  <DateRange
                    editableDateInputs={true}
                    onChange={item => setDates([item.selection])}
                    moveRangeOnFirstSelection={false}
                    ranges={dates}
                    minDate={new Date()}
                    className='date absolute top-8 right-0 z-10'
                  />
                }
              </div>
            </div>
          </div>
        </div>

        <table class="table-auto w-full drop-shadow-sm">
          <thead className='bg-[#f9f9f9]'>
            <tr>
              <th className='text-left tracking-wide p-3 font-normal'>
                <input type="checkbox" />
              </th>
              <th className='text-left tracking-wide p-3 font-normal'>UID</th>
              <th className='text-left tracking-wide p-3 font-normal'>V-ID</th>
              <th className='text-left tracking-wide p-3 font-normal'>Date</th>
              <th className='text-left tracking-wide p-3 font-normal'>Parked Time</th>
              <th className='text-left tracking-wide p-3 font-normal'>Image</th>
            </tr>
          </thead>
        </table>
      </div>
    </section>
  );
}

export default Images;