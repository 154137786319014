import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { BsSearch } from "react-icons/bs";
import { BiWifi1, BiChevronDown } from "react-icons/bi";
import { useAuth } from "../context/AuthContext";
import { AiOutlineBell } from "react-icons/ai";

export default function Navbar(){
    const [isHidden, setIsHidden] = useState(true);
    const [searchValue, setSearchValue] = useState("");
    const navigate = useNavigate();
    const { logout } = useAuth();

    const handleLogout = async () => {
        try {
            await logout();
            navigate("/login");
        } catch (error) {
            console.log(error.message);
        }
    };

    return (
        <>
            <nav className=" bg-white p-4 py-6 flex justify-end gap-x-8 border-b border-b-[#E0E0E0] ">
                <div className="w-7/12 ml-auto">
                    <div className="w-full  flex ml-auto">
                        <div className="w-9/12 flex flex-col items-center ml-auto">
                            <div className="w-11/12 flex justify-between">
                                <div className="w-9/12  flex  items-center bg-[#f9f9f9] pl-2 border-[#E0E0E0] rounded-tl-md rounded-bl-md   border">
                                    <BsSearch
                                        style={{
                                            color: "#919191",
                                        }}
                                    />
                                    <input
                                        value={searchValue}
                                        onChange={(e) => setSearchValue(e.target.value)}
                                        type="text"
                                        placeholder="Search Scooter with ID, battery"
                                        className=" bg-[#f9f9f9] text-grey pl-2 outline-none w-10/12 placeholder:text-sm"
                                    />
                                </div>
                                <button className="w-3/12  p-2 bg-primary text-white rounded-br-md rounded-tr-md">
                                    Search
                                </button>
                            </div>

                            {searchValue && (
                                <div className="bg-white shadow-md w-6/12 mt-2 absolute top-[4rem] left-50 ">
                                    <div className="flex justify-evenly  items-center p-2">
                                        <p className="bg-[#EFFEE8] p-1">
                                            <BiWifi1
                                                style={{
                                                    color: "#4E844E",
                                                    fontSize: "2rem",
                                                }}
                                            />
                                        </p>
                                        <p>H00456</p>
                                        <p className="text-green">Active</p>
                                        <p>98%</p>
                                        <p>A001234</p>
                                        <p>Lagos</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className=" flex items-center gap-8 text-2xl">
                    <div className="flex relative">
                        <AiOutlineBell />
                        <div className="w-2 absolute right-[2px] top-[2px] rounded-full h-2 bg-primary"></div>
                    </div>

                    <div
                        className="flex  items-center pr-4"
                        onClick={() => {
                            setIsHidden(!isHidden);
                        }}
                    >
                      <div className="w-8 h-8 rounded-full bg-primary ">

                      </div>
                        {/* <IoPerson
                            style={{
                                color: "#D3D3D3",
                            }}
                        /> */}
                        <BiChevronDown
                            style={{
                                color: "#D3D3D3",
                            }}
                        />

                        <div className="bg-white shadow-md w-2/12 mt-2 absolute top-[4rem] z-10 right-[1rem] ">
                            <div
                                className={`${
                                    isHidden ? "hidden" : "flex"
                                } flex-col justify-center  items-start p-2 text-base text-left `}
                            >
                                <Link
                                    to="/profile"
                                    style={{
                                        color: "black",
                                        textAlign: "left",
                                    }}
                                >
                                    View full profile
                                </Link>
                                <button className="py-2" type="button" onClick={handleLogout}>
                                    Logout
                                </button>
                                <Link
                                    to="/setting"
                                    style={{
                                        color: "black",
                                        textAlign: "left",
                                    }}
                                >
                                    Settings
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
};
