import DashboardContainer from "../layouts/DashboardContainer";
import ProfileModule from "../modules/Profile";
import { withAuth } from "../utils/hoc's/withAuth";

const Profile = () => {
  return (
    <DashboardContainer>
      <ProfileModule />
    </DashboardContainer>
  );
};

export default withAuth(Profile);
