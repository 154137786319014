import React from "react";
import Sidebar from "../components/Sidebar";
import Topbar from '../components/Topbar';

const DashboardContainer = ({ children }) => {
    return (
        <>
            <div className="flex overflow-y-hidden h-screen">
                <div className=" overflow-y-hidden h-[100vh]">
                    <Sidebar />
                </div>
                <div className="flex flex-col min-h-screen w-full bg-[#fff]">
                    <div className="">
                        <Topbar />
                    </div>
                    <div className="flex w-full h-screen my-4 overflow-y-scroll">{children}</div>
                </div>
            </div>
        </>
    );
};

export default DashboardContainer;
