import React, { useState } from 'react';
import { BsThreeDots } from 'react-icons/bs';
import { CiSearch } from 'react-icons/ci';

const Admin = () => {

  const [OpenModule, setOpenModule] = useState(false);

  const [inputs, setInputs] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    password: "",
  });

  const handleChange = (e) => {
    setInputs(prev => ({ ...prev, [e.target.name]: e.target.value }));
  }

  const handleSubmit = (e) => {

  }

  return (
    <section className="flex flex-col w-full px-[2rem] py-4">
      <div className="flex flex-row justify-between my-2">
        <h4 className="text-[30px] text-[#000] font-semibold px-4">Admin</h4>
        <div className="flex flex-row items-center justify-center gap-6">
          <div className="relative">
            <input 
              type="text" 
              placeholder='Search by Vehicle ID, Manufacturer'
              className='w-[350px] py-2 px-8 rounded-md outline-none border-[1px] border-[#e0e0e0]'
            />

            <CiSearch
              className='absolute top-3 left-3'
            />
          </div>
          <button className='text-white bg-[#1A73EB] px-6 py-2 rounded-md' onClick={() => setOpenModule(true)}>Add New</button>
        </div>
      </div>

      <div className="mt-5">
        <div className="bg-white w-full p-3 border-[1px] border-[#e0e0e0] border-b-0 rounded-md">
          <div className="flex flex-row items-center gap-5">
            <h4 className='font-semibold text-[25px] mr-5'>10 Users</h4>
            <p className='text-grey text-sm cursor-pointer'>02 Marketers</p>
            <hr className='bg-grey h-[12px] w-[0.4px]' />
            <p className='text-grey text-sm cursor-pointer'>02 Super Admin</p>
            <hr className='bg-grey h-[12px] w-[0.4px]' />
            <p className='text-grey text-sm cursor-pointer'>02 Customer Support</p>
          </div>
        </div>

        <table class="table-auto w-full drop-shadow-sm">
          <thead className='bg-[#f9f9f9]'>
            <tr>
              <th className='text-left tracking-wide p-3 font-normal'>
                <input type="checkbox" />
              </th>
              <th className='text-left tracking-wide p-3 font-semibold'>Full Name</th>
              <th className='text-left tracking-wide p-3 font-semibold'>Email Address</th>
              <th className='text-left tracking-wide p-3 font-semibold'>Phone Number</th>
              <th className='text-left tracking-wide p-3 font-semibold'>Role</th>
              <th className='text-left tracking-wide p-3 font-semibold'>Date Added</th>
              <th className='text-left tracking-wide p-3 font-semibold'>Status</th>
              <th className='text-left tracking-wide p-3 font-semibold'> </th>
            </tr>
          </thead>
          <tbody className='divide-y-4 divide-[#F6F6F6]'>
            <tr className='bg-white cursor-pointer'>
              <td className='p-3 text-sm text-grey-700'>
                <input type="checkbox" name="" id="" />
              </td>
              <td className='p-3 text-sm text-grey-700'>Nirvada Johnson</td>
              <td className='p-3 text-sm text-grey-700'>
                <a href="#localhost" className='font-semibold text-[#1A73EB] underline hover:no-underline'>nirvada@gmail.com</a>
              </td>
              <td className='p-3 text-sm text-grey-700'>
                <a href="#localhost" className='font-semibold text-[#1A73EB] underline hover:no-underline'>+2348204487833</a>
              </td>
              <td className='p-3 text-sm text-grey-700'>Marketing</td>
              <td className='p-3 text-sm text-grey-700'>07 Aug 2023</td>
              <td className='p-3 text-sm text-[#ea232d]'>Suspended</td>
              <td className='p-3 text-sm text-grey-700'>
                <BsThreeDots />
              </td>
            </tr>
            <tr className='bg-white cursor-pointer'>
              <td className='p-3 text-sm text-grey-700'>
                <input type="checkbox" name="" id="" />
              </td>
              <td className='p-3 text-sm text-grey-700'>Nirvada Johnson</td>
              <td className='p-3 text-sm text-grey-700'>
                <a href="#localhost" className='font-semibold text-[#1A73EB] underline hover:no-underline'>nirvada@gmail.com</a>
              </td>
              <td className='p-3 text-sm text-grey-700'>
                <a href="#localhost" className='font-semibold text-[#1A73EB] underline hover:no-underline'>+2348204487833</a>
              </td>
              <td className='p-3 text-sm text-grey-700'>Marketing</td>
              <td className='p-3 text-sm text-grey-700'>07 Aug 2023</td>
              <td className='p-3 text-sm text-[#53C100]'>Active</td>
              <td className='p-3 text-sm text-grey-700'>
                <BsThreeDots />
              </td>
            </tr>
            <tr className='bg-white cursor-pointer'>
              <td className='p-3 text-sm text-grey-700'>
                <input type="checkbox" name="" id="" />
              </td>
              <td className='p-3 text-sm text-grey-700'>Nirvada Johnson</td>
              <td className='p-3 text-sm text-grey-700'>
                <a href="#localhost" className='font-semibold text-[#1A73EB] underline hover:no-underline'>nirvada@gmail.com</a>
              </td>
              <td className='p-3 text-sm text-grey-700'>
                <a href="#localhost" className='font-semibold text-[#1A73EB] underline hover:no-underline'>+2348204487833</a>
              </td>
              <td className='p-3 text-sm text-grey-700'>Assessment Personnel</td>
              <td className='p-3 text-sm text-grey-700'>07 Aug 2023</td>
              <td className='p-3 text-sm text-[#ea232d]'>Suspended</td>
              <td className='p-3 text-sm text-grey-700'>
                <BsThreeDots />
              </td>
            </tr>
            <tr className='bg-white cursor-pointer'>
              <td className='p-3 text-sm text-grey-700'>
                <input type="checkbox" name="" id="" />
              </td>
              <td className='p-3 text-sm text-grey-700'>Nirvada Johnson</td>
              <td className='p-3 text-sm text-grey-700'>
                <a href="#localhost" className='font-semibold text-[#1A73EB] underline hover:no-underline'>nirvada@gmail.com</a>
              </td>
              <td className='p-3 text-sm text-grey-700'>
                <a href="#localhost" className='font-semibold text-[#1A73EB] underline hover:no-underline'>+2348204487833</a>
              </td>
              <td className='p-3 text-sm text-grey-700'>Customer Support</td>
              <td className='p-3 text-sm text-grey-700'>07 Aug 2023</td>
              <td className='p-3 text-sm text-[#53C100]'>Active</td>
              <td className='p-3 text-sm text-grey-700'>
                <BsThreeDots />
              </td>
            </tr>
            <tr className='bg-white cursor-pointer'>
              <td className='p-3 text-sm text-grey-700'>
                <input type="checkbox" name="" id="" />
              </td>
              <td className='p-3 text-sm text-grey-700'>Nirvada Johnson</td>
              <td className='p-3 text-sm text-grey-700'>
                <a href="#localhost" className='font-semibold text-[#1A73EB] underline hover:no-underline'>nirvada@gmail.com</a>
              </td>
              <td className='p-3 text-sm text-grey-700'>
                <a href="#localhost" className='font-semibold text-[#1A73EB] underline hover:no-underline'>+2348204487833</a>
              </td>
              <td className='p-3 text-sm text-grey-700'>Agent</td>
              <td className='p-3 text-sm text-grey-700'>07 Aug 2023</td>
              <td className='p-3 text-sm text-[#ea232d]'>Suspended</td>
              <td className='p-3 text-sm text-grey-700'>
                <BsThreeDots />
              </td>
            </tr>
            <tr className='bg-white cursor-pointer'>
              <td className='p-3 text-sm text-grey-700'>
                <input type="checkbox" name="" id="" />
              </td>
              <td className='p-3 text-sm text-grey-700'>Nirvada Johnson</td>
              <td className='p-3 text-sm text-grey-700'>
                <a href="#localhost" className='font-semibold text-[#1A73EB] underline hover:no-underline'>nirvada@gmail.com</a>
              </td>
              <td className='p-3 text-sm text-grey-700'>
                <a href="#localhost" className='font-semibold text-[#1A73EB] underline hover:no-underline'>+2348204487833</a>
              </td>
              <td className='p-3 text-sm text-grey-700'>Marketing</td>
              <td className='p-3 text-sm text-grey-700'>07 Aug 2023</td>
              <td className='p-3 text-sm text-[#ea232d]'>Suspended</td>
              <td className='p-3 text-sm text-grey-700'>
                <BsThreeDots />
              </td>
            </tr>
            <tr className='bg-white cursor-pointer'>
              <td className='p-3 text-sm text-grey-700'>
                <input type="checkbox" name="" id="" />
              </td>
              <td className='p-3 text-sm text-grey-700'>Nirvada Johnson</td>
              <td className='p-3 text-sm text-grey-700'>
                <a href="#localhost" className='font-semibold text-[#1A73EB] underline hover:no-underline'>nirvada@gmail.com</a>
              </td>
              <td className='p-3 text-sm text-grey-700'>
                <a href="#localhost" className='font-semibold text-[#1A73EB] underline hover:no-underline'>+2348204487833</a>
              </td>
              <td className='p-3 text-sm text-grey-700'>Marketing</td>
              <td className='p-3 text-sm text-grey-700'>07 Aug 2023</td>
              <td className='p-3 text-sm text-[#53C100]'>Active</td>
              <td className='p-3 text-sm text-grey-700'>
                <BsThreeDots />
              </td>
            </tr>
          </tbody>
        </table>

        {/* <div className="flex flex-row justify-between mt-5">
          <p>Showing 1 to 10 of 58 results</p>

          <div className="flex flex-row gap-3">
            <button className='text-[#1A73EB] bg-white border-[1px] border-[#1A73EB] rounded-md px-3 py-1 cursor-pointer'>Previous</button>
            <button className='text-[#1A73EB] bg-white border-[1px] border-[#1A73EB] rounded-md px-3 py-1 cursor-pointer'>Next</button>
          </div>
        </div> */}
      </div>
      
      {OpenModule && (
        <div className="absolute bg-white h-[100vh] w-[40vw] right-0 top-0 bottom-0 py-7 px-12 flex flex-col gap-24 ">
          <div className="flex flex-row justify-between items-center text-black ">
            <h4 className='font-bold text-xl'>Add New</h4>

            <button className='bg-[#f1f1f1] text-black font-light px-2' onClick={() => setOpenModule(false)}>x</button>
          </div>

          <form onSubmit={handleSubmit} className='flex flex-col gap-3'>
            <label htmlFor="firstname" className='font-semibold'>First Name</label>
            <input 
              type="text" 
              id='firstname' 
              name='firstname' 
              className='w-full p-3 border-[1px] border-[#e4e4e4] rounded-md mt-1 outline-none ' 
              onChange={handleChange} 
              required 
            />
            
            <label htmlFor="lastname" className='font-semibold'>Last Name</label>
            <input 
              type="text" 
              id='lastname' 
              name='lastname' 
              className='w-full p-3 border-[1px] border-[#e4e4e4] rounded-md mt-1 outline-none ' 
              onChange={handleChange} 
              required 
            />
            
            <label htmlFor="email" className='font-semibold'>Email Address</label>
            <input 
              type="email" 
              id='email' 
              name='email' 
              className='w-full p-3 border-[1px] border-[#e4e4e4] rounded-md mt-1 outline-none ' 
              onChange={handleChange} 
              value={inputs.email}
              required 
            />
            
            <label htmlFor="phone" className='font-semibold'>Phone Number</label>
            <input 
              type="number" 
              id='phone' 
              name='phone' 
              className='w-full p-3 border-[1px] border-[#e4e4e4] rounded-md mt-1 outline-none ' 
              onChange={handleChange} 
              required 
            />
            
            <label htmlFor="password" className='font-semibold'>Password</label>
            <input 
              type="password" 
              id='password' 
              name='password' 
              className='w-full p-3 border-[1px] border-[#e4e4e4] rounded-md mt-1 outline-none ' 
              onChange={handleChange} 
              required 
            />

            <button type='submit' className='w-full p-3 text-white bg-[#1A73EB] rounded-md font-bold mt-3 '>Save</button>
          </form>
        </div>
      )}
    </section>

  )
}

export default Admin;