import React from "react";
import ToggleFleet from "../../ToggleFleet/ToggleFleet";
import { BsThreeDots } from "react-icons/bs";
import { MdKeyboardArrowDown } from "react-icons/md";

const FleetTableData = [
    {
        id: "HS0122001",
        status: "Active",
        batteryLevel: "25",
        coordinate: "18845°N, 19228°W",
        price: "45/h",
        tempLock: false,
        totalTrips: "25",
    },
    {
        id: "HS0122002",
        status: "Active",
        batteryLevel: "25",
        coordinate: "18845°N, 19228°W",
        price: "45/h",
        tempLock: true,
        totalTrips: "25",
    },
    {
        id: "HS0122003",
        status: "Active",
        batteryLevel: "25",
        coordinate: "18845°N, 19228°W",
        price: "45/h",
        tempLock: true,
        totalTrips: "25",
    },
    {
        id: "HS0122004",
        status: "Docked",
        batteryLevel: "25",
        coordinate: "18845°N, 19228°W",
        price: "45/h",
        tempLock: false,
        totalTrips: "25",
    },
    {
        id: "HS0122005",
        status: "Charging",
        batteryLevel: "25",
        coordinate: "18845°N, 19228°W",
        price: "45/h",
        tempLock: false,
        totalTrips: "25",
    },
];

export const generateStatusBackground = (status) => {
    const newstatus = status.toLowerCase();
    switch (newstatus) {
        case "active":
            return "bg-[#effaeb] text-[#53C100]";
        case "docked":
            return "bg-[#e0e0e0]";
        case "charging":
            return "bg-[#f7efdb] text-[#FA9F01]";
        default:
            return "bg-[#f7efdb]";
    }
};
const FleetTable = ({ setOpenModule, openModule }) => {
    return (
        <div>
            <div className="bg-white w-full px-3 py-2 border border-[#CFCFCF] rounded-md">
                <div className="flex items-center gap-5">
                    <h4 className="font-semibold text-[25px]">100 Vehicles</h4>
                    <hr className="border-[#e0e0e0] h-[20px] border-l-[1px]" />

                    <div className="flex  items-center justify-center gap-4 rounded-md py-1 px-2">
                        <div className="border-r-[1px] border-[#e0e0e0] px-2">Filter by</div>
                        <div className="bg-[#e0e0e0] cursor-pointer rounded-md py-1 px-3">
                            Scooters
                        </div>
                        <div className="text-grey cursor-pointer">Bicycles</div>
                        <div className="text-grey cursor-pointer">Cars</div>
                        <div className="text-grey cursor-pointer">All Vehicles</div>
                    </div>
                </div>
            </div>

            <table class="table-auto w-full drop-shadow-sm mt-2">
                <thead className="bg-[#f9f9f9]">
                    <tr className="">
                        <th className="text-left tracking-wide p-3 font-semibold">Vehicle-ID</th>
                        <th className="text-left tracking-wide p-3 font-semibold flex flex-row gap-1 items-center">
                            <span className="">Status</span>
                            <MdKeyboardArrowDown />
                        </th>
                        <th className="text-left tracking-wide p-3 font-semibold">Battery Lvl</th>
                        <th className="text-left tracking-wide p-3 font-semibold">Co-ordinate</th>
                        <th className="text-left tracking-wide p-3 font-semibold">Price</th>
                        <th className="text-left tracking-wide p-3 font-semibold">Temp Lock</th>
                        <th className="text-left tracking-wide p-3 font-semibold">Ttl Trips</th>
                        <th className="tracking-wide p-3 font-semibold text-center">Actions</th>
                    </tr>
                </thead>
                <tbody className="divide-y-[5px]  divide-[#F6F6F6]">
                    {FleetTableData.map((data) => (
                        <tr className="bg-white cursor-pointer" key={data.id}>
                            <td className="p-3 text-sm text-[#1A73EB]">{data.id}</td>
                            <td className="p-3 text-sm ">
                                <span
                                    className={`${generateStatusBackground(
                                        data.status
                                    )} px-2 py-1 rounded-md`}
                                >
                                    {data.status}
                                </span>
                            </td>
                            <td className="p-3 text-sm  text-[#ea232d]  text-grey-700">
                                {data.batteryLevel} %
                            </td>
                            <td className="p-3 text-sm text-[#1A73EB]">{data.coordinate}</td>
                            <td className="p-3 text-sm">₦ {data.price}</td>

                            <td className="p-3 text-sm text-grey-700">
                                <ToggleFleet label={data.id} isChecked={data.tempLock}  disabled={true}/>
                            </td>
                            <td className="p-3 text-sm text-[#1A73EB]">{data.totalTrips} Trips</td>
                            <td
                                className="p-3 text-sm text-center flex justify-center text-[#1A73EB]"
                                onClick={() => setOpenModule(true)}
                            >
                                <BsThreeDots />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="flex flex-row justify-between mt-5">
                <p>Showing 1 to 10 of 58 results</p>

                <div className="flex flex-row gap-3">
                    <button className="text-[#1A73EB] bg-white border-[1px] border-[#1A73EB] rounded-md px-3 py-1 cursor-pointer">
                        Previous
                    </button>
                    <button className="text-[#1A73EB] bg-white border-[1px] border-[#1A73EB] rounded-md px-3 py-1 cursor-pointer">
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FleetTable;
