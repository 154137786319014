import React from "react";

const FeedbackTable = () => {
    return (
        <div>
            <div className="p-2 bg-[#F5F5F5]">
                <h1 className="text-xl font-bold">Feedback History</h1>
            </div>
            <table className="w-full mt-4 ">
                <thead className="">
                    <tr className="">
                        <th className="">UID</th>
                        <th className="">Rider</th>
                        <th className="">End time</th>
                        <th className="">Review time</th>
                        <th className="">Ratings</th>
                    </tr>
                </thead>
            </table>
        </div>
    );
};

export default FeedbackTable;
