import React, { useState, useRef } from "react";
import logo from "../../../assets/img/helgg-full-logo.png";
import { Link, useNavigate } from "react-router-dom";
import styles from "./SignUp.module.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useAuth } from "../../../context/AuthContext";
import { toast } from "react-toastify";
const SignUp = () => {
    const [values, setValues] = useState();
    const [loading, setLoading] = useState(false);
    const [passwordType, setPasswordType] = useState("password");
    const passwordRef = useRef();
    const emailRef = useRef();
    const { signup } = useAuth();
    const navigate = useNavigate();
    // const navigate = useNavigate();

    // const nextStep = () => {
    //     if (step < 3) {
    //         setStep(step + 1);
    //     } else {
    //         navigate("/login", { replace: true });
    //     }
    // };

    const showPassword = () => {
        passwordType === "password" ? setPasswordType("text") : setPasswordType("password");
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        // const {email, password} = values;
        try {
            setLoading(true);
            const message = await signup(emailRef.current.value, passwordRef.current.value);
            await toast.success(
                "Account has been created successfully, Please verify your email(check your inbox or spam)",
                { autoClose: 5000 }
            );
            console.log(message);
        } catch (error) {
            if (error.message === "Firebase: Error (auth/email-already-in-use).") {
                setLoading(false);
                return toast.error("Email already registered");
            } else if (error.message === "Firebase: Error (auth/invalid-email).") {
                setLoading(false);
                return toast.error("Invalid email format");
            }
        }

        setLoading(false);
        navigate("/login");
    };

    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    return (
        <div>
            <div className={`flex flex-col py-8 px-12`}>
                {/* Header  */}
                <div className="flex justify-between items-center">
                    <img src={logo} alt="Helgg logo" className="w-28" />
                    <button  className="text-primary text-semibold" onClick={() => navigate("/login")}>Back to Login</button>
                </div>
                {/* Card  */}
                <div className="mt-12 ">
                    <div className={` flex-1 py-4 px-4 sm:w-6/12 w-full mx-auto`}>
                        {/* Static content */}
                        {/* Steps  */}
                        <div className="flex gap-2 justify-center text-md">
                            <div className={`flex items-center gap-2 `}>
                                <span
                                    className={`w-8 h-8 rounded-full font-bold flex items-center justify-center text-white  bg-primary
                                       
                                    `}
                                >
                                    1
                                </span>
                                <p className="text-primary">Setting Up</p>
                            </div>
                        </div>
                        {/* Changing content  */}
                        <div className="w-full">
                            <form onSubmit={(e) => onSubmit(e)}>
                                <div className={`flex flex-col items-center pt-8 justify-center  w-full`}>
                                    <h3 className={`font-bold text-base md:text-2xl`}>
                                        Sign Up New Account
                                    </h3>
                                    <p className={`my-2 text-center text-base md:text-xl`}>
                                        Easily create an account by providing necessary information
                                    </p>
                                    <div className="flex flex-col w-full text-lg items-center">
                                        <div className={`flex flex-col w-full ${styles.input}`}>
                                            <label className={`font-semibold py-2 `}>Email</label>
                                            <input
                                                className={`rounded-md py-3 pl-5 border-grey border `}
                                                type="text"
                                                name="email"
                                                id="email"
                                                ref={emailRef}
                                                onChange={(e) => handleChange(e)}
                                                required
                                            />
                                        </div>
                                        <div
                                            className={`flex flex-col w-full relative ${styles.input}`}
                                        >
                                            <label className={`font-semibold py-2`}>Password</label>
                                            <input
                                                className={`relative rounded-md py-3 pl-5 border-grey border `}
                                                type={passwordType}
                                                name="password"
                                                id="password"
                                                ref={passwordRef}
                                                onChange={(e) => handleChange(e)}
                                                required
                                                minLength="7"
                                            />
                                            <button
                                                className="absolute top-[63%] left-[90%] cursor-pointer"
                                                type="button"
                                            >
                                                <span className="" onClick={showPassword}>
                                                    {passwordType === "password" ? (
                                                        <FaEye />
                                                    ) : (
                                                        <FaEyeSlash />
                                                    )}
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-center py-8 w-full ">
                                    <button
                                        type="submit"
                                        className="bg-primary text-white py-3 w-full rounded-md text-lg font-semibold"
                                        disabled={loading}
                                    >
                                        Sign Up
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* sign in  */}
                    <div className="flex justify-center pb-4 text-lg">
                        <p>
                            Already have an account ?{" "}
                            <Link to="/login" className={`text-primary font-semibold`}>
                                Sign In
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignUp;
