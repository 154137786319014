import React from "react";
import styles from "./index.module.css"

const ProfileModule = () => {
  return (
    <div className="p-12 flex  flex-col gap-y-12 h-screen w-full">
      {/* 1st section  */}
      <div className="bg-white flex flex-row px-8 py-6">
        {/* for profile image */}
        <div>
          <div className="w-56 h-56 bg-grey relative">
              <div className={`${styles.uploadButton} absolute  ` }>
                 <button className={`bg-green px-2 py-1 text-sm text-white `}>Upload photo</button>
              </div>
            </div>
        </div>

        {/* for profile information */}
        <div className="profile__information w-full flex flex-col justify-between">
          {/* name and edit  */}
          <div className="profile__name flex flex-row pl-8 justify-between ">
            <div className="flex flex-col gap-2">
              <h1 className="text-2xl font-bold">Ayebeovsa Iyamu</h1>
              <span>Lead Product Developer (Mobile)</span>
            </div>
            <div>
              <button className="bg-black text-white px-8 py-2 ">Edit</button>
            </div>
          </div>
          {/* profile details  */}
          {/* Probably would make this render based on if the button value to submit or edit to display the changebale content  */}
          <div className="profile_details flex flex-row pl-8 font-semibold justify-between">
            {/* first  */}
            <div className="flex flex-row gap-x-6">
              <div className="flex flex-col gap-y-3">
                <p>Department</p>
                <p>Email</p>
              </div>
              <div className="flex flex-col gap-y-3 text-grey-normal">
                <p>Engineering</p>
                <p>iyamu@helgg.ng</p>
              </div>
            </div>
            {/* second  */}
            <div className="flex flex-row gap-x-6">
              <div className="flex flex-col gap-y-3">
                <p>Mobile No</p>
                <p>Residence</p>
              </div>
              <div className="flex flex-col gap-y-3 text-grey-normal">
                <p>+2349032....</p>
                <p>Iyanapaja</p>
              </div>
            </div>
            {/* third  */}
            <div className="flex flex-row gap-x-6">
              <div className="flex flex-col gap-y-3">
                <p>Helgg ID</p>
                <p>Working Rating</p>
              </div>
              <div className="flex flex-col gap-y-3 text-grey-normal">
                <p>H09132</p>
                <p>4.9/5</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 2nd section  */}
      <div className="bg-white px-8 pb-12">
        <h4 className="font-bold text-xl py-4">About</h4>
        <p className=" text-lg">
          Born in Maida Vale, London, Turing was raised in southern England. He graduated at King's College, Cambridge, with a degree in mathematics. Whilst he was a fellow at Cambridge, he published
          a proof demonstrating that some purely mathematical yes-no questions can never be answered by computation and defined a Turing machine, and went on to prove the halting problem for Turing
          machines is undecidable. In 1938, he obtained his PhD from the Department of Mathematics at Princeton University.
        </p>
      </div>
    </div>
  );
};

export default ProfileModule;
