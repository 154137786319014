import React from 'react'

const DetailPane = ({ title, description, value, type }) => {
  return (
	<div className="flex flex-row justify-between w-full">
	<div className="flex flex-col  w-[35%]">
		<h5 className=" text-black">{title}</h5>
		<p className=" text-grey">{description}</p>
	</div>

	<div className="w-[65%]">
		{title === "Deactivate Vehicle" ? (
			<button className="py-3 w-[40%] bg-[#ea232d] text-white rounded-md">
				Deactivate
			</button>
		) : (
			<input
				type="text"
				disabled
				value={value ? value : ""}
				className="w-full p-3 border-[1px] text-[#5E5E5E] border-[#e4e4e4] rounded-md outline-none"
			/>
		)}
	</div>
</div>
  )
}

export default DetailPane

