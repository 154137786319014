import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../../../context/AuthContext";
import logo from "../../../assets/img/helgg-full-logo.png";
import { AiOutlineInfoCircle } from "react-icons/ai";

const SigninComponent = () => {
    const { login } = useAuth();
    const [helggID, setHelggID] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const auth = useAuth();
    const {user} = auth;
    const location = useLocation();

    const handleSubmit = async (e) => {
        const email = helggID;
        e.preventDefault();
        try {
            await login(email, password);
            const to = location?.state?.from?.pathname || "/";
            navigate(to, { replace: true });
            toast.success("User is successfully logged in");
        } catch (error) {
            if(error.message === "Please verify your email before logging in"){
                toast.error("Please verify your email before logging in");
            }
            else if (
                error.message === "Firebase: Error (auth/wrong-password)." ||
                "Firebase: Error (auth/invalid-email)."
            ) {
                toast.error("Wrong credentials");
            }
            setHelggID("");
            setPassword("");
        }
    };

    useEffect(()=> {
        if(user && user.emailVerified){
            navigate("/")
        }
    })
    return (
        <div className="flex h-screen">
            <div className="flex flex-[1] flex-col py-8 px-16">
                <div className="flex items-center">
                    <img src={logo} alt="Helgg logo" className="w-28" />
                </div>

                <div className="w-full mx-auto flex flex-col py-4 my-auto justify-between gap-8">
                    <div className="flex flex-col justify-start items-center w-full">
                        <div className="flex flex-col w-full mx-auto">
                            <h2 className="text-2xl mb-1 font-semibold">Log In Account</h2>
                            <p className=" mb-[1rem]">Welcome! Please Enter your Helgg Details</p>
                        </div>

                        <form
                            onSubmit={(e) => handleSubmit(e)}
                            className="flex flex-col w-full mx-auto "
                        >
                            <label className="font-semibold text-lg" htmlFor="HelggID">HelggID</label>
                            <input
                                type="text"
                                id="HelggID"
                                name="HelggID"
                                className="bg-[#F9F9F9] p-4 hover:outline-[#1A73EB] hover:outline-1 focus:outline-[#1A73EB] focus:outline-1 mb-4"
                                value={helggID}
                                required
                                onChange={(e) => {
                                    setHelggID(e.currentTarget.value);
                                }}
                            />
                            <label className="font-semibold text-lg" htmlFor="Password">Password</label>
                            <input
                                type="password"
                                id="Password"
                                name="Password"
                                className="bg-[#F9F9F9] p-4 hover:outline-[#1A73EB] hover:outline-1 focus:outline-[#1A73EB] focus:outline-1"
                                value={password}
                                required
                                onChange={(e) => {
                                    setPassword(e.currentTarget.value);
                                }}
                            />
                            <Link to="/forgot-password" className="text-primary mt-2">
                               Forgot Password
                            </Link>
                            <button type="submit" className="w-full bg-primary text-white my-7 py-4 rounded-md">
                                Log In
                            </button>
                        </form>
                    </div>

                    <div className="flex flex-col items-start bg-[#ecebeb] py-2 px-4 rounded-md">
                        <div className="flex flex-row justify-center items-center gap-2">
                            <AiOutlineInfoCircle />
                            <p>Note</p>
                        </div>

                        <div className="text-xs">
                            You can request for a new password only if you do not remember your login password or if you notice any unauthorize actions
                        </div>
                    </div>
                    {/* <p className="text-center">
                        Don't have an account ?{" "}
                        <Link to="/signup" className="text-green ">
                            <span className="text-primary">Sign Up </span>
                        </Link>
                    </p> */}
                </div>
            </div>

            <div className="flex flex-[1] bg-register bg-cover bg-center min-w-full bg-no-repeat">
                {/* <img src={signInImg} alt="Man parking scooter" className=" w-full" /> */}
            </div>
        </div>
    );
};

export default SigninComponent;
