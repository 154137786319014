import React from "react";
import logo from "../../assets/img/helgg-logo-alone.png";
import PasswordCard from "../../components/modules/ForgotPassword/PasswordCard";
import { useNavigate } from "react-router";

const ForgotPassword = () => {
  const navigate = useNavigate();
    // const [page, setPage] = useState("forgotPassword");

    // const handlePageChange = (page) => {
    //     setPage(page);
    // };
    return (
        <div className="min-h-screen">
            <div className="flex py-8 px-16 ">
                <div className="flex items-center justify-between w-full">
                    <img src={logo} alt="Helgg logo" className="w-8" />
                    <button className="text-primary " onClick={() => navigate("/login")}>Back to Login</button>
                </div>
            </div>
            <div className="w-full mt-8 flex justify-center my-auto">
                <PasswordCard />
            </div>
        </div>
    );
};

export default ForgotPassword;
