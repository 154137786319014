import DashboardContainer from "../layouts/DashboardContainer";
import ImagesModule from "../modules/Images";
import { withAuth } from "../utils/hoc's/withAuth";

const Images = () => {
  return (
    <DashboardContainer>
      <ImagesModule />
    </DashboardContainer>
  );
};

export default withAuth(Images);