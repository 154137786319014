import DashboardContainer from "../layouts/DashboardContainer";
import TripsModule from "../modules/Trips";
import { withAuth } from "../utils/hoc's/withAuth";

const Trips = () => {
  return (
    <DashboardContainer>
      <TripsModule />
    </DashboardContainer>
  );
};

export default withAuth(Trips);