import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from "react-router-dom";
// import ScaleLoader from "react-spinners/ScaleLoader";

import SignUp from "./pages/Auth/SignUp/SignUp";
import Profile from "./pages/Profile";
import Signin from "./pages/Auth/SignIn";
import { useAuth } from "./context/AuthContext";
import Admin from "./pages/Admin";
import Trips from "./pages/Trips";
import Fleet from "./pages/Fleet";
import VehicleReview from "./pages/VehicleReview";
import Images from "./pages/Images";
import ScaleLoader from "./components/ScaleLoader";
import FleetSettings from "./pages/FleetSettings";
import ForgotPassword from './pages/Auth/ForgotPassword';

const ControlPanel = React.lazy(() => import("./pages/ControlPanel"));
const Home = React.lazy(() => import("./pages/Home"));
const RideAssessment = React.lazy(() => import("./pages/RideAssessment"));
const VehicleOverview = React.lazy(() => import("./pages/VehicleOverview"));

const VehicleRegistration = React.lazy(() => import("./pages/VehicleRegistration"));
const VehicleDetails = React.lazy(() => import("./pages/VehicleDetails"));

const Users = React.lazy(() => import("./pages/Users"));

// const FormContainer = React.lazy(() =>
//   import("./modules/VehicleRegistration/formcontainer")
// );
const Maintenance = React.lazy(() => import("./pages/Maintenance/Maintenance"));

const Location = React.lazy(() => import("./pages/Location"));




function RequireAuth({ children, user }) {
    const location = useLocation();
    if (!user) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }
    return children;
}
const AppRoutes = () => {
    const { user } = useAuth();
    return (
        <Router>
            <Routes>
                <Route
                    caseSensitive="false"
                    end="true"
                    path="/"
                    element={
                        <Suspense
                            fallback={
                                <div className="">
                                    <ScaleLoader />
                                </div>
                            }
                        >
                            <RequireAuth user={user}>
                                <Home />
                            </RequireAuth>
                        </Suspense>
                    }
                />
                <Route caseSensitive="false" end="true" path="/login" element={<Signin />} />
                <Route caseSensitive="false" end="true" path="/forgot-password" element={<ForgotPassword />} />
                <Route caseSensitive="false" end="true" path="/signup" element={<SignUp />} />
                <Route
                    caseSensitive="false"
                    end="true"
                    path="/profile"
                    element={
                        <RequireAuth user={user}>
                            <Profile />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/vehicle-registration/:tab"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <ScaleLoader />
                                </div>
                            }
                        >
                            <VehicleRegistration />
                        </Suspense>
                    }
                />
                <Route
                    caseSensitive="false"
                    end="true"
                    path="/fleet"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <ScaleLoader />
                                </div>
                            }
                        >
                            <RequireAuth user={user}>
                                <Fleet />
                            </RequireAuth>
                        </Suspense>
                    }
                />
                <Route
                    caseSensitive="false"
                    end="true"
                    path="/fleet-settings"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <ScaleLoader />
                                </div>
                            }
                        >
                            <RequireAuth user={user}>
                                <FleetSettings />
                            </RequireAuth>
                        </Suspense>
                    }
                />
                <Route
                    caseSensitive="false"
                    end="true"
                    path="/users"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <ScaleLoader />
                                </div>
                            }
                        >
                            <RequireAuth user={user}>
                                <Users />
                            </RequireAuth>
                        </Suspense>
                    }
                />
                <Route
                    caseSensitive="false"
                    end="true"
                    path="/users/:tab"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <ScaleLoader />
                                </div>
                            }
                        >
                            <RequireAuth user={user}>
                                <Users />
                            </RequireAuth>
                        </Suspense>
                    }
                />
                <Route
                    caseSensitive="false"
                    end="true"
                    path="/trips"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <ScaleLoader />
                                </div>
                            }
                        >
                            <RequireAuth user={user}>
                                <Trips />
                            </RequireAuth>
                        </Suspense>
                    }
                />
                <Route
                    caseSensitive="false"
                    end="true"
                    path="/admin"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <ScaleLoader />
                                </div>
                            }
                        >
                            <RequireAuth user={user}>
                                <Admin />
                            </RequireAuth>
                        </Suspense>
                    }
                />
                <Route
                    path="/ride-assessment"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <ScaleLoader />
                                </div>
                            }
                        >
                            <RideAssessment />
                        </Suspense>
                    }
                />

                {/* <Route
                caseSensitive="false"
                end="true"
                path="/vehicle-registration/overview"
                element={
                <Suspense
                fallback={
                    <div>
                    <ScaleLoader />
                    </div>
                }
                >
                <VehicleRegistrationOverView />
                </Suspense>
                }
                /> */}
                <Route
                    caseSensitive="false"
                    end="true"
                    path="/vehicle-registration/review"
                    element={
                        <Suspense
                        fallback={
                            <div>
                            <ScaleLoader />
                            </div>
                        }
                        >
                            <VehicleReview />
                        </Suspense>
                    }
                />

                <Route
                    caseSensitive="false"
                    end="true"
                    path="/vehicle-registration"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <ScaleLoader />
                                </div>
                            }
                        >
                            <VehicleRegistration />
                        </Suspense>
                    }
                />

                <Route
                    caseSensitive="false"
                    end="true"
                    path="/vehicle-overview"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <ScaleLoader />
                                </div>
                            }
                        >
                            <VehicleOverview />
                        </Suspense>
                    }
                />
                <Route
                    caseSensitive="false"
                    end="true"
                    path="/vehicle-details"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <ScaleLoader />
                                </div>
                            }
                        >
                            <VehicleDetails />
                        </Suspense>
                    }
                />
                <Route
                    path="/vehicle-details/:tab"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <ScaleLoader />
                                </div>
                            }
                        >
                            <VehicleDetails />
                        </Suspense>
                    }
                />
                <Route
                    caseSensitive="false"
                    end="true"
                    path="/controlpanel"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <ScaleLoader />
                                </div>
                            }
                        >
                            <ControlPanel />
                        </Suspense>
                    }
                />
                <Route
                    caseSensitive="false"
                    end="true"
                    path="/controlpanel/:tab"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <ScaleLoader />
                                </div>
                            }
                        >
                            <ControlPanel />
                        </Suspense>
                    }
                />

                <Route
                    caseSensitive="false"
                    end="true"
                    path="/maintenance"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <ScaleLoader />
                                </div>
                            }
                        >
                            <Maintenance />
                        </Suspense>
                    }
                />
                <Route
                    caseSensitive="false"
                    end="true"
                    path="/maintenance/:tab"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <ScaleLoader />
                                </div>
                            }
                        >
                            <Maintenance />
                        </Suspense>
                    }
                />

                <Route
                    caseSensitive="false"
                    end="true"
                    path="/location"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <ScaleLoader />
                                </div>
                            }
                        >
                            <Location />
                        </Suspense>
                    }
                />
                
                <Route
                    caseSensitive="false"
                    end="true"
                    path="/images"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <ScaleLoader />
                                </div>
                            }
                        >
                            <Images />
                        </Suspense>
                    }
                />
            </Routes>
        </Router>
    );
};

export default AppRoutes;
