import DashboardContainer from "../layouts/DashboardContainer";
import AdminModule from "../modules/Admin";
import { withAuth } from "../utils/hoc's/withAuth";

const Admin = () => {
  return (
    <DashboardContainer>
      <AdminModule />
    </DashboardContainer>
  );
};

export default withAuth(Admin);