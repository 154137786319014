import { useNavigate } from "react-router";

export const ReviewCard = ({ title, details }) => {
    const navigate = useNavigate();
	return (
        <div className="px-8 flex flex-col gap-y-4 py-2">
            <div className="flex justify-between items-center">
                <h1 className="text-xl font-semibold">{title}</h1>
                <button
                    className="px-4 py-1 font-semibold rounded-md border border-primary  text-primary"
                    onClick={() => navigate("/vehicle-registration/identification")}
                >
                    {" "}
                    Edit
                </button>
            </div>
			<div className="bg-[#F5F5F5] p-4 grid grid-cols-4 grid-rows-2 rounded-sm gap-y-4">
				{details.map((data) => (
					<div className="flex flex-col gap-y-3">
						<p className="text-black">{data.title}</p>
						<p className="text-[#767676] text-[14px]">{data.detail}</p>
					</div>	
				))}
			</div>
        </div>
    );
};
