import DashboardContainer from "../layouts/DashboardContainer";
import Review from "../modules/VehicleRegistration/Review";
// import Review from "../modules/VehicleRegistration/Review";
import { withAuth } from "../utils/hoc's/withAuth";

const VehicleReview = () => {
  return (
    <DashboardContainer>
      <Review />
    </DashboardContainer>
  );
};

export default withAuth(VehicleReview);
