import "./ToggleFleet.css";

const ToggleFleet = ({ label, isChecked, disabled }) => {
    // const [isChecked, setIsChecked] = useState(false);
    return (
        
        <div className="">
            <label className="toggle" htmlFor={label}>
                {/* <input aria-label="toggle-button" class="toggle-checkbox" type="checkbox" id={label} name={label} checked={isChecked} onChange={handleChange}/> */}
                <input
                    aria-label="toggle-button"
                    className="toggle-checkbox"
                    type="checkbox"
                    id={label}
                    disabled= {disabled || false}
                    name={label}
                    defaultChecked={isChecked || false}
                />
                <div className="toggle-switch"></div>
            </label>
        </div>
    );
};

export default ToggleFleet;
