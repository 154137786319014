import React from 'react'
import { BsStar } from 'react-icons/bs'
import Progressbar from './Progressbar'
import FeedbackTable from './FeedbackTable'

const FeedbackTab = () => {
  return (
	<div className='flex flex-col gap-12'>
    <div className='flex gap-12 w-full max-w-xl mx-auto'>
      <div className="flex flex-col items-center">
         <h1 className='text-[80px] font-bold'>4.5</h1>
         <div className='flex gap-2 items-center'>
          <BsStar/>
          <BsStar/>
          <BsStar/>
          <BsStar/>
          <BsStar/>
         </div>
      </div>
      <div className='w-full flex flex-col gap-2'>
        <Progressbar percentage={70} comment="Very Good"/>
        <Progressbar percentage={60} comment="Good"/>
        <Progressbar percentage={40} comment="Nice"/>
        <Progressbar percentage={30} comment="Okay"/>
        <Progressbar percentage={10} comment="Okay"/>
      </div>
    </div>

    <FeedbackTable/>
  </div>
  )
}

export default FeedbackTab