import React from 'react'

const FormInput = ({label, name, id,  onChange, additionalClassName, type, value, placeholder }) => {
  return (
	<div className=" flex flex-col gap-2 w-full ">
		<label className='font-semibold' htmlFor=''>{label}</label>
		<input
          type={type}
          id={id}
          name={name}
          className={`${additionalClassName} border-[#D8D8D8] outline-primary border h-12 w-full px-4 rounded-md`}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
	</div>
  )
}

export default FormInput