import React from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { FiAtSign } from "react-icons/fi";
import { AiOutlineCar, AiOutlineUser } from "react-icons/ai";
import { MdOutlineTrackChanges } from "react-icons/md";
import { PiUsers } from "react-icons/pi";
import { RxDashboard } from "react-icons/rx";
import { BiLogOut, BiSolidCarMechanic, BiTrip } from "react-icons/bi";
import { useAuth } from "../context/AuthContext";
import logo from "../assets/img/helgg-logo-alone.png";
import { CiImageOn } from "react-icons/ci";

export const getNavLinkStyle = (pathname) => {
    return (isActive) => ({
        color: isActive ? "#1A73EB" : "#D3D3D3",
        borderBottom: isActive ? "1px solid #1A73EB" : "none",
        paddingBottom: isActive ? "3px" : "0",
    });
};

const Sidebar = () => {
    let location = useLocation();
    const navigate = useNavigate();
    const { logout } = useAuth();

    const handleLogout = async () => {
        try {
            await logout();
            navigate("/login");
        } catch (error) {
            console.log(error.message);
        }
    };
    return (
        <div className="flex flex-col py-2 px-5 h-screen justify-between  border-r border-r-[#E0E0E0] w-full">
            <div className="pt-4 pb-8 self-center">
                <NavLink end="true" to="/">
                    <img src={logo} alt="Helgg logo" className="w-8 h-8" />
                </NavLink>
            </div>
            <div
                className="flex flex-col gap-y-8 px-2 justify-center items-center
             "
            >
                
                <NavLink
                    caseSensitive={true}
                    end={true}
                    to="/"
                    style={getNavLinkStyle("/vehicle-overview")(
                        location.pathname === "/" ||
                            location.pathname.includes("/vehicle-overview") ||
                            location.pathname.includes("/vehicle-details")
                    )}
                >
                    <RxDashboard
                        style={{
                            fontSize: "25",
                        }}
                    />
                </NavLink>

                <NavLink
                    caseSensitive={true}
                    end={true}
                    to="/fleet"
                    style={getNavLinkStyle("/fleet")(location.pathname.includes("/fleet"))}
                >
                    <AiOutlineCar
                        style={{
                            fontSize: "25",
                        }}
                    />
                </NavLink>

                <NavLink
                    caseSensitive={true}
                    end={true}
                    to="/location"
                    style={getNavLinkStyle("/location")(location.pathname.includes("/location"))}
                >
                    <MdOutlineTrackChanges
                        style={{
                            fontSize: "25",
                        }}
                    />
                </NavLink>

                <NavLink
                    caseSensitive={true}
                    end={true}
                    to="/users"
                    style={getNavLinkStyle("/users")(location.pathname.includes("/users"))}
                >
                    <PiUsers
                        style={{
                            fontSize: "25",
                        }}
                    />
                </NavLink>

                <NavLink
                    caseSensitive={true}
                    end={true}
                    to="/trips"
                    style={getNavLinkStyle("/trips")(location.pathname.includes("/trips"))}
                >
                    <BiTrip
                        style={{
                            fontSize: "25",
                        }}
                    />
                </NavLink>

                <NavLink
                    caseSensitive={true}
                    end={true}
                    to="/vehicle-registration"
                    style={getNavLinkStyle("/vehicle-registration")(
                        location.pathname.includes("/vehicle-registration")
                    )}
                >
                    <FiAtSign
                        style={{
                            fontSize: "25",
                        }}
                    />
                </NavLink>

                <NavLink
                    caseSensitive={true}
                    end={true}
                    to="/admin"
                    style={getNavLinkStyle("/admin")(location.pathname.includes("/admin"))}
                >
                    <AiOutlineUser
                        style={{
                            fontSize: "25",
                        }}
                    />
                </NavLink>

                <NavLink
                    caseSensitive={true}
                    end={true}
                    to="/maintenance"
                    style={getNavLinkStyle("/maintenance")(
                        location.pathname.includes("/maintenance")
                    )}
                >
                    <BiSolidCarMechanic
                        style={{
                            fontSize: "25",
                        }}
                    />
                </NavLink>
                
                <NavLink
                    caseSensitive={true}
                    end={true}
                    to="/images"
                    style={getNavLinkStyle("/images")(
                        location.pathname.includes("/images")
                    )}
                >
                    <CiImageOn
                        style={{
                            fontSize: "25",
                        }}
                    />
                </NavLink>

                {/* <NavLink
                    caseSensitive={true}
                    end={true}
                    to="/controlpanel"
                    style={getNavLinkStyle("/controlpanel")(
                        location.pathname.includes("/controlpanel")
                    )}
                >
                    <IoLogoGameControllerA
                        style={{
                            fontSize: "27",
                        }}
                    />
                </NavLink> */}
                
                
            </div>
            <button  className="mt-auto pb-4 self-center" onClick={handleLogout}>
                <BiLogOut
                    style={{
                        fontSize: "25",
                        color: "#ea232d",
                    }}
                />
                </button>
        </div>
    );
};

export default Sidebar;
