import React, { useState } from 'react';
import { CiSearch } from 'react-icons/ci';
import FleetDetails from '../../components/modules/Fleet/FleetDetails';
import { useNavigate } from 'react-router';
import FleetTable from '../../components/modules/Fleet/FleetTable';

const Fleet = () => {
  const [openModule, setOpenModule] = useState(false);
  const navigate = useNavigate();

  return (
    <section className="flex flex-col w-full px-[2rem] py-4 ">
      <div className="flex flex-row justify-between my-2">
        <h4 className="text-[30px] text-[#000] font-semibold px-4">Fleet</h4>
        <div className="flex items-center justify-center gap-x-4">
          <div className="relative border-r-[0.5px] border-r-[#CFCFCF] pr-4">
            <input 
              type="text" 
              placeholder='Search by Vehicle ID, Manufacturer'
              className='w-[350px] py-2 px-8 rounded-md outline-none border-[1px] border-[#e0e0e0]'
            />

            <CiSearch 
              className='absolute top-3 left-3'
            />
          </div> 
          <button className="text-[#1A73EB] border border-[#1A73EB] px-6 py-2 rounded-md" onClick={() => navigate("/vehicle-registration")}>Add New Vehicle</button>
        </div>
      </div>

      <div className="mt-5">
        <FleetTable openModule={openModule} setOpenModule={setOpenModule}/>
      </div>
      
      {openModule && (
       <FleetDetails openModule={openModule} setOpenModule={setOpenModule}/>
      )}
    </section>

  )
}

export default Fleet;