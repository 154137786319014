import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import ScaleLoader from '../../components/ScaleLoader';


export const withAuth = (Component) => (props) => {
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    if (true) {
      setIsAuthorized(true);
    } else {
      <Navigate to="/login" replace={true} />;
    }
  }, []);

  return isAuthorized ? <Component {...props} /> : <ScaleLoader />; // While waiting, show loading animation
};
