import React from "react";
import DetailPane from "../components/modules/Fleet/DetailPane";
import { BsArrowLeft, BsInfoCircle } from "react-icons/bs";

const FleetSettings = () => {
    return (
        <div>
            {/* topbar */}
            <div className="py-8">
				<div>
					<div className="flex gap-2 text-primary items-center">
						<BsArrowLeft/>
						Go Back
					</div>
					<h1 className="border-l pl-4">Fleet Setting</h1>
				</div>
				<button className="bg-[#CFCFCF] px-6 py-2">
					Reset All Changes
				</button>
			</div>
            {/* body  */}
            <div className="border-t flex">
                {/* sidebar */}
                <div className="border-r flex w-full px-8 justify-center py-4 flex-[0.1]">
                    <ul className="flex flex-col group-hover:bg-[#CFCFCF]">
                        {["scooter", "bicycle", "car", "bus", "train"].map((item) => {
                            return <li className="py-2 px-4 capitalize group-hover:bg-[#CFCFCF]">{item}</li>;
                        })}
                    </ul>
                </div>
                {/* content */}
                <div className="flex flex-col px-8 py-4 flex-1 ">
                    <div className="flex flex-col gap-4 max-w-5xl">
                        <h1 className="text-2xl font-bold">Scooters</h1>
                        <div className="flex flex-col gap-3">
                            <DetailPane
                                title="Set base price (Price in NGN)"
                                description="Set base price (Price in NGN)"
                                value=""
                            />
                            <DetailPane
                                title="Set scooter pricing (Price in NGN)"
                                description="Base price is the starting price for all scooter ride"
                                value=""
                            />
                            <DetailPane
                                title="Maximum pause time"
                                description="Maximum time a user can pause a ride"
                                value="2 minutes"
                            />
                            <DetailPane
                                title="Increment after max pause time"
                                description="Additional charges after maximum pause time"
                                value="5.00/minutes"
                            />
                        </div>
                        <div className="flex flex-col gap-3 border-t pt-4">
                            <h1>For Bookings</h1>
                            <DetailPane
                                title="Set booking fee"
                                description="Additional charges after maximum pause time"
                                value=""
                            />
                            <DetailPane
                                title="Booking cancelation limit"
                                description="Additional charges after maximum pause time"
                                value=""
                            />
                            <DetailPane
                                title="Booking cancelation fee"
                                description="Additional charges after maximum pause time"
                                value="2 minutes"
                            />
                        </div>
                        <div className="flex w-full justify-end">
                            <div className="w-[50%] flex gap-6">
                                <button className="border-red-[#E71D36] rounded-md w-full py-2 border bg-[#E71D36] bg-opacity-10 text-[#E71D36]">
                                    Discard Changes
                                </button>
                                <button className="bg-primary rounded-md text-white w-full py-2">
                                    Apply Changes
                                </button>
                            </div>
                        </div>
                    </div>
					<div className="flex items-center gap-2 mt-8">
						<BsInfoCircle className="text-primary text-2xl"/>
						<p>Take note that changes will take effect on all scooters after clicking on set changes</p>
					</div>
                </div>
            </div>
        </div>
    );
};

export default FleetSettings;
