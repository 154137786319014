import React, { useState } from "react";
import InfoTab from "./InfoTab";
import { IoClose } from "react-icons/io5";
import ControlTab from "./ControlTab";
import FeedbackTab from "./FeedbackTab";

const FleetDetails = ({ openModule, setOpenModule }) => {
    const fleetdata = {
        id: "HS343234",
        model: "Honda",
        date: "12/12/2021",
        color: "Red",
        status: "Active",
        price: "1000",
        vehicleControls: {
            disabled: true,
            alarm: false,
            disabledSimcard: false,
            freeAccess: false,
        },
        communicationControls: {
            ping: false,
        },
        feedbacks: [

        ]
    };
    const [toggleTab, setToggleTab] = useState(1);
    return (
        <div className="absolute bg-white h-[100vh] w-[60vw]  rounded-md shadow-md right-0 top-0 bottom-0 py-7 px-12 flex flex-col gap-8 ">
            <div className="flex justify-between items-center text-black ">
                <h4 className="font-semibold text-3xl">Vehicle {fleetdata?.id}</h4>

                <button
                    className="bg-[#f1f1f1] text-black font-light p-2"
                    onClick={() => setOpenModule(false)}
                >
                    <IoClose />
                </button>
            </div>

            {/* Toogle Tabs  */}
            <div className="flex flex-row gap-12 border-b border-b-[#F5F5F5]">
                <div
                    className={`text-md py-2 cursor-pointer ${
                        toggleTab === 1 ? "border-b-2 text-[#1A73EB]" : "text-grey"
                    }`}
                    onClick={() => setToggleTab(1)}
                >
                    Vehicle info
                </div>
                <div
                    className={`text-md py-2 cursor-pointer ${
                        toggleTab === 2 ? "border-b-2 text-[#1A73EB]" : "text-grey"
                    }`}
                    onClick={() => setToggleTab(2)}
                >
                    Controls
                </div>
                <div
                    className={`text-md py-2 cursor-pointer ${
                        toggleTab === 3 ? "border-b-2 text-[#1A73EB]" : "text-grey"
                    }`}
                    onClick={() => setToggleTab(3)}
                >
                    Feedbacks
                </div>
            </div>

            {/* Tab Content */}
            {toggleTab === 1 && <InfoTab fleetdata={fleetdata} />}

            {toggleTab === 2 && <ControlTab vehicleControls={fleetdata.vehicleControls} communicationControls={fleetdata.communicationControls}/>}
            {toggleTab === 3 && <FeedbackTab />}
        </div>
    );
};

export default FleetDetails;
