import React, { useState } from 'react';
import { CiSearch } from 'react-icons/ci';
import { addDays, format } from "date-fns";
import { DateRange } from "react-date-range";

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';

const Trips = () => {

  const [dates, setDates] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection'
    }
  ]);

  const [openDate, setOpenDate] = useState(false);

  return (
    <section className="flex flex-col w-full px-[2rem] py-4">
      <div className="flex flex-row justify-between my-2">
        <h4 className="text-[30px] text-[#000] font-semibold px-4">Trips</h4>
        <div className="flex flex-row items-center justify-center gap-6">
          <div className="relative">
            <input 
              type="text" 
              placeholder='Search by Vehicle ID, Manufacturer'
              className='w-[350px] py-2 px-8 rounded-md outline-none border-[1px] border-[#e0e0e0]'
            />

            <CiSearch
              className='absolute top-3 left-3'
            />
          </div>
        </div>
      </div>

      <div className="mt-5">
        <div className="bg-white w-full flex flex-row justify-between p-3 border-[1px] border-[#e0e0e0] border-b-0 rounded-md">
          <div className="flex flex-row items-center gap-5">
            <h4 className='font-semibold text-[25px] mr-5'>128 Trips</h4>
            <hr className="border-[#e0e0e0] h-[20px] border-l-[1px]" />
            
            <div className="flex flex-row items-center justify-center gap-4 border-[1px] border-[#e0e0e0] rounded-md py-1 px-2">
              <div className="border-r-[1px] border-[#e0e0e0] px-2">Filter by</div>
              <div className="bg-[#e0e0e0] cursor-pointer rounded-md px-1">Scooters</div>
              <div className="text-grey cursor-pointer">Bicycles</div>
              <div className="text-grey cursor-pointer">Cars</div>
              <div className="text-grey cursor-pointer">All Vehicles</div>
            </div>
          </div>

          <div className="flex flex-row items-center gap-5">
            <p className='text-black text-sm cursor-pointer bg-[#e6e6e6] py-2 px-3 rounded-md'>Today</p>
            <p className='text-grey text-sm cursor-pointer'>This Week</p>
            <p className='text-grey text-sm cursor-pointer'>This Month</p>
            <div className='text-grey text-sm cursor-pointer border-solid border-[2px] border-[#f6f6f6] p-1 rounded-md relative'>
              {/* From : <span className='text-[#1A73EB]'>7 July 23 - 7 July 24</span> */}
              <span className='text-[#1A73EB]' onClick={() => setOpenDate(!openDate)}>
                {`${format(dates[0].startDate, "dd/MM/yyyy")} to ${format(dates[0].endDate, "dd/MM/yyyy")}`}
              </span>
              {openDate && 
                <DateRange
                  editableDateInputs={true}
                  onChange={item => setDates([item.selection])}
                  moveRangeOnFirstSelection={false}
                  ranges={dates}
                  minDate={new Date()}
                  className='date absolute top-8 right-0 z-10'
                />
              }
            </div>
          </div>
        </div>

        <table class="table-auto w-full drop-shadow-sm">
          <thead className='bg-[#f9f9f9]'>
            <tr>
              <th className='text-left tracking-wide p-3 font-semibold'>HVID</th>
              <th className='text-left tracking-wide p-3 font-semibold'>Rider</th>
              <th className='text-left tracking-wide p-3 font-semibold'>Cost</th>
              <th className='text-left tracking-wide p-3 font-semibold'>Status</th>
              <th className='text-left tracking-wide p-3 font-semibold'>Total Dist</th>
              <th className='text-left tracking-wide p-3 font-semibold'>Started At</th>
              <th className='text-left tracking-wide p-3 font-semibold'>Finished At</th>
              <th className='text-left tracking-wide p-3 font-semibold'>Rating</th>
              <th className='text-left tracking-wide p-3 font-semibold'>Issue Rpts</th>
            </tr>
          </thead>
          <tbody className='divide-y-4 divide-[#F6F6F6]'>
            <tr className='bg-white cursor-pointer'>
              <td className='p-3 text-sm text-[#1A73EB]'>HS273648</td>
              <td className='p-3 text-sm text-[#1A73EB]'>Kyle Micheal</td>
              <td className='p-3 text-sm text-grey-700'>$250.00</td>
              <td className='p-3 text-sm text-grey-700'>
                <span className='py-1.5 px-2 text-xs font-medium tracking-wider text-[#53C100] bg-green-unique rounded-md'>Finished</span>
              </td>
              <td className='p-3 text-sm text-grey-700'>3 km</td>
              <td className='p-3 text-sm text-grey-700'>08:30</td>
              <td className='p-3 text-sm text-grey-700'>17:00</td>
              <td className='p-3 text-sm text-grey-700'>4.5</td>
              <td className='p-3 text-sm text-grey-700'>No Issues</td>
            </tr>
            <tr className='bg-white cursor-pointer'>
              <td className='p-3 text-sm text-[#1A73EB]'>HS273648</td>
              <td className='p-3 text-sm text-[#1A73EB]'>Kyle Micheal</td>
              <td className='p-3 text-sm text-grey-700'>$250.00</td>
              <td className='p-3 text-sm text-grey-700'>
                <span className='py-1.5 px-2 text-xs font-medium tracking-wider text-[#53C100] bg-green-unique rounded-md'>Finished</span>
              </td>
              <td className='p-3 text-sm text-grey-700'>3 km</td>
              <td className='p-3 text-sm text-grey-700'>08:30</td>
              <td className='p-3 text-sm text-grey-700'>17:00</td>
              <td className='p-3 text-sm text-grey-700'>4.5</td>
              <td className='p-3 text-sm text-grey-700'>No Issues</td>
            </tr>
            <tr className='bg-white cursor-pointer'>
              <td className='p-3 text-sm text-[#1A73EB]'>HS273648</td>
              <td className='p-3 text-sm text-[#1A73EB]'>Kyle Micheal</td>
              <td className='p-3 text-sm text-grey-700'>$250.00</td>
              <td className='p-3 text-sm text-grey-700'>
                <span className='py-1.5 px-2 text-xs font-medium tracking-wider text-[#53C100] bg-green-unique rounded-md'>Finished</span>
              </td>
              <td className='p-3 text-sm text-grey-700'>3 km</td>
              <td className='p-3 text-sm text-grey-700'>08:30</td>
              <td className='p-3 text-sm text-grey-700'>17:00</td>
              <td className='p-3 text-sm text-grey-700'>4.5</td>
              <td className='p-3 text-sm text-grey-700'>No Issues</td>
            </tr>
            <tr className='bg-white cursor-pointer'>
              <td className='p-3 text-sm text-[#1A73EB]'>HS273648</td>
              <td className='p-3 text-sm text-[#1A73EB]'>Kyle Micheal</td>
              <td className='p-3 text-sm text-grey-700'>$250.00</td>
              <td className='p-3 text-sm text-grey-700'>
                <span className='py-1.5 px-2 text-xs font-medium tracking-wider text-[#53C100] bg-green-unique rounded-md'>Finished</span>
              </td>
              <td className='p-3 text-sm text-grey-700'>3 km</td>
              <td className='p-3 text-sm text-grey-700'>08:30</td>
              <td className='p-3 text-sm text-grey-700'>17:00</td>
              <td className='p-3 text-sm text-grey-700'>4.5</td>
              <td className='p-3 text-sm text-grey-700'>No Issues</td>
            </tr>
            <tr className='bg-white cursor-pointer'>
              <td className='p-3 text-sm text-[#1A73EB]'>HS273648</td>
              <td className='p-3 text-sm text-[#1A73EB]'>Kyle Micheal</td>
              <td className='p-3 text-sm text-grey-700'>$250.00</td>
              <td className='p-3 text-sm text-grey-700'>
                <span className='py-1.5 px-2 text-xs font-medium tracking-wider text-[#53C100] bg-green-unique rounded-md'>Finished</span>
              </td>
              <td className='p-3 text-sm text-grey-700'>3 km</td>
              <td className='p-3 text-sm text-grey-700'>08:30</td>
              <td className='p-3 text-sm text-grey-700'>17:00</td>
              <td className='p-3 text-sm text-grey-700'>4.5</td>
              <td className='p-3 text-sm text-grey-700'>No Issues</td>
            </tr>
            <tr className='bg-white cursor-pointer'>
              <td className='p-3 text-sm text-[#1A73EB]'>HS273648</td>
              <td className='p-3 text-sm text-[#1A73EB]'>Kyle Micheal</td>
              <td className='p-3 text-sm text-grey-700'>$250.00</td>
              <td className='p-3 text-sm text-grey-700'>
                <span className='py-1.5 px-2 text-xs font-medium tracking-wider text-[#53C100] bg-green-unique rounded-md'>Finished</span>
              </td>
              <td className='p-3 text-sm text-grey-700'>3 km</td>
              <td className='p-3 text-sm text-grey-700'>08:30</td>
              <td className='p-3 text-sm text-grey-700'>17:00</td>
              <td className='p-3 text-sm text-grey-700'>4.5</td>
              <td className='p-3 text-sm text-grey-700'>No Issues</td>
            </tr>
            <tr className='bg-white cursor-pointer'>
              <td className='p-3 text-sm text-[#1A73EB]'>HS273648</td>
              <td className='p-3 text-sm text-[#1A73EB]'>Kyle Micheal</td>
              <td className='p-3 text-sm text-grey-700'>$250.00</td>
              <td className='p-3 text-sm text-grey-700'>
                <span className='py-1.5 px-2 text-xs font-medium tracking-wider text-[#53C100] bg-green-unique rounded-md'>Finished</span>
              </td>
              <td className='p-3 text-sm text-grey-700'>3 km</td>
              <td className='p-3 text-sm text-grey-700'>08:30</td>
              <td className='p-3 text-sm text-grey-700'>17:00</td>
              <td className='p-3 text-sm text-grey-700'>4.5</td>
              <td className='p-3 text-sm text-grey-700'>No Issues</td>
            </tr>
            <tr className='bg-white cursor-pointer'>
              <td className='p-3 text-sm text-[#1A73EB]'>HS273648</td>
              <td className='p-3 text-sm text-[#1A73EB]'>Kyle Micheal</td>
              <td className='p-3 text-sm text-grey-700'>$250.00</td>
              <td className='p-3 text-sm text-grey-700'>
                <span className='py-1.5 px-2 text-xs font-medium tracking-wider text-[#53C100] bg-green-unique rounded-md'>Finished</span>
              </td>
              <td className='p-3 text-sm text-grey-700'>3 km</td>
              <td className='p-3 text-sm text-grey-700'>08:30</td>
              <td className='p-3 text-sm text-grey-700'>17:00</td>
              <td className='p-3 text-sm text-grey-700'>4.5</td>
              <td className='p-3 text-sm text-grey-700'>No Issues</td>
            </tr>
            <tr className='bg-white cursor-pointer'>
              <td className='p-3 text-sm text-[#1A73EB]'>HS273648</td>
              <td className='p-3 text-sm text-[#1A73EB]'>Kyle Micheal</td>
              <td className='p-3 text-sm text-grey-700'>$250.00</td>
              <td className='p-3 text-sm text-grey-700'>
                <span className='py-1.5 px-2 text-xs font-medium tracking-wider text-[#53C100] bg-green-unique rounded-md'>Finished</span>
              </td>
              <td className='p-3 text-sm text-grey-700'>3 km</td>
              <td className='p-3 text-sm text-grey-700'>08:30</td>
              <td className='p-3 text-sm text-grey-700'>17:00</td>
              <td className='p-3 text-sm text-grey-700'>4.5</td>
              <td className='p-3 text-sm text-grey-700'>No Issues</td>
            </tr>
          </tbody>
        </table>

        <div className="flex flex-row justify-between mt-5">
          <p>Showing 1 to 10 of 28 results</p>

          <div className="flex flex-row gap-3">
            <button className='text-[#1A73EB] bg-white border-[1px] border-[#ç] rounded-md px-3 py-1 cursor-pointer'>Previous</button>
            <button className='text-[#1A73EB] bg-white border-[1px] border-[#1A73EB] rounded-md px-3 py-1 cursor-pointer'>Next</button>
          </div>
        </div>
      </div>
    </section>

  )
}

export default Trips;