import React from "react";

const Progressbar = ({ percentage, comment }) => {
    return (
        <div className="flex gap-4 items-center">
                <div className="overflow-hidden w-full h-1 md:h-3 text-xs flex bg-[#F5F5F5] mb-1">
                    <div
                        style={{ width: `${percentage}%` }}
                        className="
                                    shadow-none
                                    flex flex-col
                                    text-center
                                    whitespace-nowrap
                                    text-white
                                    justify-center
                                    bg-[#FA9F01]
                                    "
                    />
                </div>

            {comment && <p className=" whitespace-nowrap pb-2">{comment}</p>}
        </div>
    );
};

export default Progressbar;
