import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router";
import { ReviewCard } from "../../components/ReviewCard";

const Review = () => {
	const IdentificationDetails = [
		{
			title: "Manufacturer",
			detail: "Sheng chen Chinese company"
		},
		{
			title: "Shipping Number",
			detail: "38738738373837383"
		},
		{
			title: "Weight",
			detail: "3.2 kg"
		},
		{
			title: "Dimension",
			detail: "10 X 20 X 30 X 40"
		},
		{
			title: "Vehicle Serial Number",
			detail: "7980TH489201"
		},
		{
			title: "Date Arrived",
			detail: "03/05/2022 - 08:40 am"
		},
		{
			title: "Color",
			detail: "Pale green"
		},
		{
			title: "Generated ID",
			detail: "123450000"
		}
	]
	const ThrottleDetails = [
		{
			title: "Manufacturer",
			detail: "Sheng chen Chinese company"
		},
		{
			title: "Shipping Number",
			detail: "38738738373837383"
		},
		{
			title: "Weight",
			detail: "3 kg"
		},
		{
			title: "Dimension",
			detail: "10 X 20 X 30 X 40"
		},
		{
			title: "Serial Number",
			detail: "7980TH489201"
		},
		{
			title: "Date Arrived",
			detail: "03/05/2022 - 08:40 am"
		},
		{
			title: "Color",
			detail: "Pale green"
		}
	]
	const GsmDetails = [
		{
			title: "Manufacturer",
			detail: "Sheng chen Chinese company"
		},
		{
			title: "GSM Number",
			detail: "+234 8133473663"
		},
		{
			title: "IMEI Number",
			detail: "291297387"
		},
		{
			title: "GPS Manufacturer",
			detail: "Sheng chen Chinese company"
		},
		{
			title: "Sim Brand",
			detail: "MTN"
		},
		{
			title: "Network Type",
			detail: "4g"
		},
		{
			title: "Country Code",
			detail: "+234"
		},
		{
			title: "GPS  Serial Number",
			detail: "2725375262"
		}
	]
	const EcuDetails = [
		{
			title: "Manufacturer",
			detail: "Sheng chen Chinese company"
		},
		{
			title: "Shipping Number",
			detail: "38738738373837383"
		},
		{
			title: "Weight",
			detail: "3 kg"
		},
		{
			title: "Serial Number",
			detail: "7980TH489201"
		},
		{
			title: "Date Arrived",
			detail: "03/05/2022 - 08:40 am"
		},
		{
			title: "Dimension",
			detail: "10 X 20 X 30 X 40"
		}
	]
	const BatteryDetails = [
		{
			title: "Manufacturer",
			detail: "Sheng chen Chinese company"
		},
		{
			title: "Shipping Number",
			detail: "38738738373837383"
		},
		{
			title: "Voltage",
			detail: "5V"
		},
		{
			title: "Dimension",
			detail: "10 X 20 X 30 X 40"
		},
		{
			title: "Serial Number",
			detail: "7980TH489201"
		},
		{
			title: "Date Arrived",
			detail: "03/05/2022 - 08:40 am"
		},
		{
			title: "Capacity",
			detail: "34kva"
		},
	]
	
    const navigate = useNavigate();
    return (
        <>
            <div className="flex flex-col gap-y-4 bg-white rounded-md w-full m-4 py-4">
                {/* header  */}
                <div className="flex justify-between items-center border-b pb-4 px-8">
                    <h1 className="text-xl font-semibold">Registration Review</h1>
                    <div className="flex gap-x-4">
                        <button
                            className="flex gapp-2 items-center  px-4 py-1 font-semibold rounded-md  bg-grey-light text-grey-verylight "
                            onClick={() => navigate(-1)}
                        >
                            <IoArrowBack />
                            Back
                        </button>
                        <button
                            className="px-4 py-1 font-semibold rounded-md text-white  bg-primary"
                            onClick={() => navigate("/fleet")}
                        >
                            {" "}
                            Save and Continue
                        </button>
                    </div>
                </div>

				<div className="flex flex-col gap-y-4">
					<ReviewCard title="Identification" details={IdentificationDetails}/>
					<ReviewCard title="Throttle & Bell" details={ThrottleDetails}/>
					<ReviewCard title="Tyres" details={IdentificationDetails}/>
					<ReviewCard title="GSM & GPS" details={GsmDetails}/>
					<ReviewCard title="ECU" details={EcuDetails}/>
					<ReviewCard title="Motor" details={IdentificationDetails}/>
					<ReviewCard title="Battery" details={BatteryDetails}/>	
				</div>
            </div>
        </>
    );
};

export default Review;
